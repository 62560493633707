import * as React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { graphql } from "gatsby";
import { Header, ArticleSummary, Footer } from "../components";

const BlogPostStyles = styled.div`
  padding: 0 80px;
  h1 {
    text-align: center;
  }
  p,
  ul {
    color: ${(props) => props.theme.text.paragraph};
  }
  @media screen and (max-width: 640px) {
    padding: 0 17px;
  }
`;

const BlogPage = ({ data }) => (
  <main>
    <Header />
    <BlogPostStyles>
      <h1>Zerrtech Blog</h1>
      {data.blogPosts.nodes.map((node) => (
        <ArticleSummary
          key={node.id}
          slug={node.slug}
          title={node.title}
          date={node.date}
          body={node.body}
        />
      ))}
    </BlogPostStyles>
    <Footer />
  </main>
);

BlogPage.propTypes = {
  // eslint-disable-next-line
  data: PropTypes.object.isRequired,
};

export default BlogPage;

export const query = graphql`
  query {
    blogPosts: allContentfulBlogPost(sort: { fields: date, order: DESC }) {
      nodes {
        id
        title
        slug
        date
        body {
          raw
          references {
            ... on ContentfulAsset {
              contentful_id
              __typename
              gatsbyImageData(placeholder: BLURRED)
              file {
                contentType
                url
                fileName
              }
            }
            ... on ContentfulBlogPost {
              contentful_id
              __typename
              title
              slug
            }
          }
        }
      }
    }
  }
`;
